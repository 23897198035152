@import '../../../../../../themes/main.scss';

o8-icon {
  display: flex;
}
.o8-custom-icon {
  display: flex;
  svg {
    width: 24px;
    height: 24px;
  }
  &.custom-icon-primary {
    svg > * {
      fill: $primary-color;
    }
  }
  &.custom-icon-accent {
    svg > * {
      fill: white;
    }
  }
  &.custom-icon-danger {
    svg > * {
      fill: $error-color-dark;
    }
  }
  &.custom-icon-accent {
    svg > * {
      fill: $warning-color-dark;
    }
  }
}
